<template>
  <div class="video-box">
    <video
      :id="idKay"
      :src="sources"
      :controls="isPlay"
      style="width: 100%; height: 100%"
      :poster="poster"
      controlslist="nodownload"
    ></video>
    <div
      v-if="!isPlay"
      class="video-img"
      :style="`background-image: url(${poster});`"
      @click="onPlay"
    >
      <i class="el-icon-video-play"></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isPlay: false,
    };
  },
  props: {
    sources: {
      type: String,
      default: "",
    },
    idKay: {
      type: String,
      default: "",
    },
    // 视频封面
    poster: {
      type: String,
      default: "",
    },
  },
  methods: {
    // 播放
    onPlay() {
      this.isPlay = true;
      this.videoPlay();
    },
    // 禁止视频拖动快进
    videoPlay() {
      let _this = this;
      let resetTime = 0; // 拖动后重置播放时间
      let curTime = 0; // 当前播放时间
      let vd = document.getElementById(this.idKay);
      let getCurTime = localStorage.getItem("resetTime");
      // console.log("getCurTime --- ", getCurTime);
      if (getCurTime > 0.1) {
        curTime = getCurTime;
        resetTime = getCurTime;
        vd.addEventListener("play", function () {
          setTimeout(function () {
            vd.currentTime = getCurTime;
            setInterval(timer, 100);
          }, 2000);
        });
      } else {
        vd.play();
        setInterval(timer, 100);
      }

      function timer() {
        curTime = vd.currentTime;
        var apartTime = curTime - resetTime;
        if (apartTime > 2) {
          vd.currentTime = resetTime;
        } else {
          resetTime = curTime;
        }
        _this.curTime = curTime;
      }

      vd.addEventListener("pause", function () {
        localStorage.setItem("remTime", _this.curTime);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.video-box {
  position: relative;
  .video-box video {
    display: inline-block;
    vertical-align: baseline;
  }
  .video-img {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 999;
    // background: url(/web/pic/v.jpg) no-repeat;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    i {
      color: #ffffff;
      font-size: 70px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
