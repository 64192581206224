<!-- 视频详情 -->
<template>
  <div class="video-details">
    <Crumbs :breadList="crumbs" />
    <div class="main">
      <div class="title">
        {{ item ? (item ? item.title : "") : "" }}
      </div>
      <div class="date">时间：{{ item ? item.createTime : "" }}</div>
      <div style="width: 100%">
        <NewVideo v-if="item && item.url" :sources="item.url" :idKay="'review'"></NewVideo>
      </div>
      <div class="bz" v-if="item && item.memo">
        描述： {{ item ? (item ? item.memo : "") : "" }}
      </div>
    </div>
  </div>
</template>
<script>
import Crumbs from "@/components/crumbs/index";
import { getTreeItem } from "@/utils/index";
import NewVideo from "@/components/newVideo.vue"; // 视频播放器
export default {
  components: { Crumbs, NewVideo },
  data() {
    return {
      item: {
        url: ''
      },
      crumbs: [],
    };
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    init() {
      let myMenus = JSON.parse(sessionStorage.getItem("menus"));
      this.parentItem = getTreeItem(myMenus, this.$route.query.pid, "id");
      this.menus = this.parentItem.children; // 菜单
      let sid = this.$route.query.sid;
      if (sid) {
        this.childrenItem = getTreeItem(myMenus, this.$route.query.sid, "id");
        this.menus.forEach((item, index) => {
          if (item.id == this.childrenItem.id) {
            this.selectedIndex = index;
          }
        });
      } else {
        this.selectedIndex = 0;
      }
      if (this.parentItem.children.length > 0) {
        this.type = this.menus[this.selectedIndex].type;
      } else {
        this.list = [];
        this.item = null;
        this.total = 0;
        this.pageSize = 0;
        this.current = 1;
      }
      this.getDetails(this.$route.query.id);
      this.updateCrumbs(); // 构建面包
    },
    async getDetails(id) {
      let res = await this.$api.second.getVideoDetial({
        id: id,
      });
      console.log("查询详情", res);
      if (res.status == 200) {
        this.item = res.data;
      }
    },
    updateCrumbs() {
      if (this.crumbs.length == 0) {
        this.crumbs.push({
          name: "首页",
          link: "/",
        });
        // if (this.parentItem.children.length > 0) {
          this.crumbs.push({
            name: this.parentItem.name,
            link:
              "/video?pid=" +
              this.parentItem.id +
              (this.childrenItem ? "&sid=" + this.parentItem.children[0].id : ""),
          });
          this.crumbs.push({
            name: "详情",
            link: "",
          });
        // }
      } else {
        if (this.parentItem.children.length > 0) {
          this.crumbs.splice(1, 1, {
            name: this.parentItem.name,
            link:
              "/video?pid=" +
              this.parentItem.id +
              (this.childrenItem ? "&sid=" + this.parentItem.children[0].id : ""),
          });
          this.crumbs.push({
            name: "详情",
            link: "",
          });
        } else {
          this.crumbs.splice(1, 2);
          this.crumbs.push({
            name: this.parentItem.name,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.video-details {
  position: relative;
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #222222;
    line-height: 25px;
    padding-top: 35px;
    padding-bottom: 25px;
  }
  .date {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
    margin-bottom: 35px;
  }
  .bz {
    padding: 20px;
    background-color: #ece8e8;
    font-size: 14px;
  }
  .html {
    font-size: 16px;
    font-weight: 400;
    color: #222222;
    line-height: 32px;
  }
  .btm {
    width: 100%;
    float: left;
    margin-top: 130px;
    .link {
      display: block;
      .not-a {
        color: #666666;
        &:hover {
          color: #666666;
        }
      }
      a {
        font-size: 14px;
        color: #222222;
        line-height: 32px;
        &:hover {
          color: #c01515;
        }
      }
    }
  }
  .read {
    position: absolute;
    top: -25px;
    right: 10px;
    color: #666666;
    font-size: 14px;
  }
}
</style>
